import {
  SET_NOTIFICATION,
  RESET_NOTIFICATION,
} from "../types";

const DEFAULT_STATE = {
  isError: false,
  notification: [],
};

const clubNotification = (
  state = DEFAULT_STATE,
  { type, isError, notification }
) => {
  switch (type) {
    case SET_NOTIFICATION:
      return { isError, notification };
    case RESET_NOTIFICATION:
      return { isError: false, notification: [] };
    default:
      return state;
  }
};

export default clubNotification;
