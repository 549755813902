import React, { memo } from "react";

import Image from "next/image";
import footerImage from "../../../../public/logoWithoutText.png";

import { FooterAddressNavigation } from "./FooterAddressNavigation";

const DefaultFooter = memo(() => {
  return (
    <footer className="main-footer main-default-footer">
      <div className="container">
        <div className="main-footer-outer">
          <div className="main-footer-inner">
            <div className="main-footer-logo-block">
              <Image src={footerImage} height={43} width={43} alt="logo" className="main-footer-logo" />
            </div>
            <div className="main-footer-wrapper">
              <span className="main-footer-subtitle">Instasport</span>
              <FooterAddressNavigation />
            </div>
          </div>
        </div>
      </div>

      <style jsx global>{`
        @import "./Footer.scss?5";
      `}</style>
    </footer>
  );
});

export { DefaultFooter };
