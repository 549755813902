import { memo } from "react";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

export const Portal = memo(({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    const { body, documentElement } = document,
      documentWidth = documentElement.clientWidth,
      windowWidth = window.innerWidth,
      scrollBarWidth = windowWidth - documentWidth || 0,
      navbar = document.getElementsByClassName("main-navbar")[0];

    body.style.overflow = "hidden";
    body.style.position = windowWidth <= 500 ? "fixed" : "relative";
    body.style.height = "100%";
    body.style.width = "100%";
    body.style.marginRight = `${scrollBarWidth}px`;

    return () => {
      body.removeAttribute("style");
      if (navbar) navbar.removeAttribute("style");
      setMounted(false);
    };
  }, []);

  return mounted
    ? createPortal(children, document.querySelector("#modal"))
    : null;
});
