import React from "react";

import { HocWithPortal } from "../../../hocs/hocWithPortal";
import clsx from "clsx";

const CircularProgressIndicator = ({
  size = null,
  width = null,
  backgroundColor = "",
  fullscreen = false,
  fill = null,
  isPortal = false,
}) => {
  const modalComponent = () => (
    <div
      className={clsx("circular-progress-wrapper", {
        "circular-progress-wrapper--full": fullscreen,
      })}
    >
      <div
        className="circular-progress"
        style={width && { width: `${width}px`, height: `${width}px` }}
      >
        <svg
          className="circular-progress__svg"
          width={size}
          height={size}
          viewBox="22 22 44 44"
        >
          <circle
            className="circular-progress__circle circular-progress--circle-indeterminate"
            cx="44"
            cy="44"
            r="20.2"
            fill="none"
            strokeWidth="2"
          ></circle>
        </svg>
      </div>
      <style jsx>{`
        @import "./CircularProgressIndicator.scss?";
      `}</style>
    </div>
  );

  return HocWithPortal(modalComponent, isPortal)();
};

export { CircularProgressIndicator };
