export const getSeoFields = ({
  tagTitle = "",
  tagDescription = "",
  tagKeywords = "",
  ogType = "article",
  ogSiteName = "instasport.ua",
  ogTitle = "",
  ogDescription = "",
  ogImage = null,
  ogImageWidth = 800,
  ogImageHeight = 300,
  ogImageAlt = "",
  ogImageType = "image/png",
  alternate = null,
  ogUrl = alternate,
  icon = "/favicon.ico",
  iconTouch = "/logo192.png",
  googleTag = null,
} = {}) => ({
  tagTitle,
  tagDescription,
  tagKeywords,
  ogType,
  ogSiteName,
  ogTitle,
  ogDescription,
  ogImage,
  ogImageWidth,
  ogImageHeight,
  ogImageAlt,
  ogImageType,
  ogUrl,
  alternate,
  icon,
  iconTouch,
  googleTag,
});
