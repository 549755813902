import React, { useMemo } from "react";

import { useTranslation } from "next-i18next";
import { PHONE, EMAIL } from "../../../constants";
import { useRouter } from "next/router";
import { getShortPhone } from "../../../utils/common/getShortPhone";
import { GET_CONTACTS } from "../../../graphql/queries";
import { useQuery } from "@apollo/client";

const FooterAddressNavigation = () => {
  const [t] = useTranslation();

  const router = useRouter();
  const {
    query: { partnerSlug },
  } = router;

  const { data: { social: { phone = PHONE, email = EMAIL } = {} } = {} } =
    useQuery(GET_CONTACTS, {
      variables: { slug: partnerSlug ? partnerSlug : "staff" },
    });

  const phoneShort = useMemo(() => {
    return getShortPhone(phone);
  }, [phone]);

  return (
    <>
      <a href={`tel:${phoneShort}`} className="landing-footer__phone-title">
        {phone}
      </a>

      <a href={`mailto:${email}`} className="landing-footer__email-title">
        {email}
      </a>
    </>
  );
};

export { FooterAddressNavigation };
