import { gql } from "@apollo/client";

export const USER_EMAIL_DATA = gql`
  query User {
    user {
      id
      email
      emailConfirmed
    }
  }
`;

export const GET_USER = gql`
  query User {
    user {
      id
      phone
      email
      firstName
      lastName
      birthday
      gender
      avatarUser
      phoneConfirmed
      emailConfirmed
      passwordConfirmed
    }
  }
`;

export const USER_DATA_FRAGMENT = gql`
  fragment UserDataFragment on UserType {
    id
    phone
    email
    firstName
    lastName
    birthday
    gender
    avatarUser
    phoneConfirmed
    emailConfirmed
  }
`;

const PROFILE_FRAGMENT = gql`
  fragment ProfileDataFragment on ProfileType {
    id
    account
    cashbackAccount
    promocode
    rulesAccepted
    leadAllowed
    visitsCount
    clubVisitsCount
    showInEvent
    smsEnabled
    emailEnabled
    discoverClub {
      id
    }
  }
`;

const FAMILY_FRAGMENT = gql`
  fragment FamilyDataFragment on ProfileType {
    id
    avatar
    phone
    email
    firstName
    lastName
    birthday
    gender
    manager
    super
    instructor
    relation
    relationTitle
  }
`;

export const GET_ALL_USER_DATA = gql`
  ${PROFILE_FRAGMENT}
  ${FAMILY_FRAGMENT}

  query AllUserData {
    profile {
      ...ProfileDataFragment
    }
    family {
      ...FamilyDataFragment
    }
  }
`;
