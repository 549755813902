export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_CITY = "CHANGE_CITY";
export const SET_ERROR_PAGE = "SET_ERROR_PAGE";
export const SET_AUTHORIZATION = "SET_AUTHORIZATION";
export const RESET_PASS_AUTHORIZATION = "RESET_PASS_AUTHORIZATION";
export const RESET_AUTHORIZATION = "RESET_AUTHORIZATION";
export const SET_TOKENS = "SET_TOKENS";
export const RESET_TOKENS = "RESET_TOKENS";
export const CHECK_TOKENS = "CHECK_TOKENS";
export const SET_CITIES = "SET_CITIES";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_CLUB_COLORS = "SET_CLUB_COLORS";
export const SET_LANDING_PARTNER = "SET_LANDING_PARTNER";
export const RESET_LANDING_PARTNER = "RESET_LANDING_PARTNER";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const SET_PARTNER_SLUG = "SET_PARTNER_SLUG";

export const ADD_FAMILY_MEMBER = "ADD_FAMILY_MEMBER";
export const EDIT_FAMILY_MEMBER = "EDIT_FAMILY_MEMBER";
export const REMOVE_FAMILY_MEMBER = "REMOVE_FAMILY_MEMBER";
export const SET_FAMILY_MEMBERS = "SET_FAMILY_MEMBERS";
export const SET_LOADING_FAMILY = "SET_LOADING_FAMILY";
export const SET_ERROR_FAMILY = "SET_ERROR_FAMILY";
export const SET_FAMILY_ID = "SET_FAMILY_ID";
export const REMOVE_FAMILY_MEMBERS = "REMOVE_FAMILY_MEMBERS";

export const SET_NAVBAR_LINKS = "SET_NAVBAR_LINKS";
export const SET_SCHEDULE_VIEW = "SET_SCHEDULE_VIEW";
// export const REMOVE_NAVBAR_LINK = "REMOVE_NAVBAR_LINK";
// export const ADD_NAVBAR_LINK = "ADD_NAVBAR_LINK";

export const SET_HEADER_MODAL = "SET_HEADER_MODAL";
export const RESET_HEADER_MODAL = "RESET_HEADER_MODAL";
export const SET_RULES = "SET_RULES";
export const RESET_RULES_ACCEPTED = "RESET_RULES_ACCEPTED";
export const SET_IS_PROFILE = "SET_IS_PROFILE";
export const SET_BASE_URL = "SET_BASE_URL";

export const SELECT_SPORT_CATEGORY = "SELECT_SPORT_CATEGORY";

