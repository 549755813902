import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import useUser from "../../hooks/useUser";
import Router from "next/router";
import { CircularProgressIndicator } from "./CircularProgressIndicator/CircularProgressIndicator";
import { setAuthorization } from "../../utils/redux/actionCreators/actions";
import { useDispatch } from "react-redux";

import { useDiscoverData, useRulesData } from "../../utils/apollo/reactivities";

let previousPathname = null;

export const RouteGuard = ({ children, authProtected = false }) => {
  const router = useRouter();
  const {
    query: { club },
  } = router;
  const dispatch = useDispatch();
  const isRulesAccepted = useRulesData();
  const isDiscoverAccepted = useDiscoverData();

  const [{ authorized, isLoading }, setState] = useState({
    authorized: false,
    isLoading: true,
  });

  const { isAuthorized, isReady, rulesError, discoverClubRequired } = useUser({
    skip: !club,
  }); //rulesChecking

  useEffect(() => {
    if (!isReady) return;

    authCheck(Router.asPath);

    // on route change start - hide page content by setting authorized to false
    const hideContent = (url) => {
      const path = url.split("?")[0];

      // previousPathname = path;
      if (previousPathname && previousPathname !== path && authProtected)
        setState({ authorized: false, isLoading: true });
    };

    Router.events.on("routeChangeStart", hideContent);

    // on route change complete - run auth check
    Router.events.on("routeChangeComplete", authCheck);

    //   router.beforePopState(({ as }) => {

    //     if (as !== router.asPath) {
    //         // Will run when leaving the current page; on back/forward actions
    //         // Add your logic here, like toggling the modal state
    //     }
    //     return true;
    // });

    return () => {
      Router.events.off("routeChangeStart", hideContent);
      Router.events.off("routeChangeComplete", authCheck);
    };
  }, [
    isReady,
    isAuthorized,
    isRulesAccepted,
    isDiscoverAccepted,
    discoverClubRequired,
    authProtected,
  ]); //rulesChecking

  function authCheck(url) {
    const path = url.split("?")[0];
    // console.log(url, previousPathname, "1");

    const oldPreviousPathname = previousPathname;
    if (previousPathname !== path) previousPathname = path;

    // if (loading && authProtected)
    //   return setState({ authorized: false, isLoading: false });
    // console.log(isAuthorized, rulesChecking, rulesError, rulesAccepted);
    if (!isAuthorized) {
      if (authProtected) {
        const clubPath = `/club/${club}`;

        dispatch(setAuthorization()); //{ nextUrl: url } //!router.asPath,
        // console.log(clubPath, "clubPath  ", oldPreviousPathname);
        return Router.replace(
          {
            pathname: clubPath,
            query: { nextUrl: url }, //returnUrl  router.asPath
          },
          clubPath
        );
      }

      return setState({ authorized: false, isLoading: false });
    }

    if (club && isRulesAccepted === null) return;

    const rulesPath = `/club/${club}/rules`;
    // console.log("ff", url, path,isRulesAccepted);
    if (
      rulesPath !== path &&
      rulesPath !== oldPreviousPathname &&
      // !rulesChecking &&
      rulesError?.graphQLErrors?.[0]?.result === 7 &&
      !isRulesAccepted

      // ||
      // (!rulesChecking && !rulesAccepted)
    ) {
      Router.replace(
        {
          pathname: rulesPath,
          query: {
            nextUrl: url, // ! router.asPath
          },
        },
        rulesPath
      );
      return;
    }

    const discoverPath = `/club/${club}/discover`;
    if (
      discoverPath !== path &&
      discoverPath !== oldPreviousPathname &&
      !isDiscoverAccepted &&
      discoverClubRequired && isRulesAccepted
    ) {
      Router.replace(
        {
          pathname: discoverPath,
          query: {
            nextUrl: url,
          },
        },
        discoverPath
      );
      return;
    }

    setState({ authorized: true, isLoading: false });
  }

  // console.log("fff", authorized, isLoading, authProtected, children);

  if (!authorized && isLoading)
    return <CircularProgressIndicator fullscreen isPortal />;
  return (!authProtected || authorized) && children;
};
