import { makeVar, gql } from "@apollo/client";

export const tokensDefault = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: false,
  exp: 0,
};

export const userDefault = {
  phone: "",
  firstName: "",
  // lastName: "",
  email: "",
  // avatarUser: "",
  // gender: 0,
  // birthday: "",
  // phoneConfirmed: false,
  emailConfirmed: false,
  phoneConfirmed: false,
  passwordConfirmed: false,
};

export const tokens = makeVar(tokensDefault);

export const GET_TOKENS = gql`
  query GetAuthTokens {
    authTokens @client {
      accessToken
      refreshToken
      isAuthorized
    }
  }
`;

export const keyClub = makeVar(null);

export const GET_KEY_CLUB = gql`
  query KeyClub {
    # id
    keyClub @client
    # {
    #   key
    # }
  }
`;

export const pageLanguage = makeVar(null);

export const GET_PAGE_LANGUAGE = gql`
  query PageLanguage {
    # id
    pageLanguage @client
    # {
    #   key
    # }
  }
`;

export const familyData = makeVar([]);
export const userData = makeVar(userDefault);
export const rulesData = makeVar(null); //{ isReadyRules: false, rulesAccepted: false }
export const discoverData = makeVar(null); //{ isReadyRules: false, rulesAccepted: false }
export const authorizationData = makeVar({});
export const pageCity = makeVar("");
export const pageCountry = makeVar("");
