import React, { memo } from "react";

import { FooterTitleNavigation } from "./FooterTitleNavigation";

import { GET_CLUB_HEADER_DATA } from "../../../graphql/queries/queriesClub";
import { FOOTER_MONOPAY_SVG, FOOTER_MASTERCARD_SVG, FOOTER_VISA_SVG } from "../../../constants/svgClubConstants";
import { useTranslation } from "next-i18next";
import { useQuery } from "@apollo/client";

const ClubFooter = memo(({ additionalQueryFields: { showOffer = false } = {} }) => {
  const [t] = useTranslation();

  const { data: { club: clubData = {} } = {} } = useQuery(GET_CLUB_HEADER_DATA, {
    variables: { showOffer },
  });

  const { address, company, showCompanyDetails, phone = "", email = "", offerEnabled } = clubData;

  return (
    <footer className="main-footer main-club-footer">
      <div className="container">
        <div className="club-footer-inner">
          <div className="club-footer__logo-block">
            {FOOTER_MONOPAY_SVG}
            <div className="club-footer__vertical-block">
              {FOOTER_VISA_SVG} {FOOTER_MASTERCARD_SVG}
            </div>
          </div>
          <div className="club-footer__navigation">
            <p className="club-footer__navigation-title">{`${t("documents")}:`}</p>
            <FooterTitleNavigation offerEnabled={offerEnabled} />
          </div>

          <div className="club-footer__contacts">
            {showCompanyDetails && (
              <>
                <p className="club-footer__contacts-title">{`${t("contacts")}:`}</p>

                {phone && (
                  <a href={`tel:${phone}`} className="club-footer__contacts-item">
                    {phone}
                  </a>
                )}
                {email && (
                  <a href={`mailto:${email}`} className="club-footer__contacts-item">
                    {email}
                  </a>
                )}
                {address && <p className="club-footer__contacts-item">{address}</p>}
                {/* {[phone, address].map(
                  (item) =>
                    item && (
                      <p key={item} className="club-footer__contacts-item">
                        {item}
                      </p>
                    )
                )} */}

                <p className="club-footer__contacts-company">{company}</p>
              </>
            )}
          </div>
        </div>
      </div>

      <style jsx global>{`
        @import "./Footer.scss?";
      `}</style>
    </footer>
  );
});

export { ClubFooter };
