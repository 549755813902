import React, { memo } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { useTranslation } from "next-i18next";
import { FOOTER_NAVIGATION_ITEMS } from "./languageConstants";

const FooterTitleNavigation = memo(({ offerEnabled }) => {
  const router = useRouter();
  const [t] = useTranslation();

  const {
    query: { club },
  } = router;

  return FOOTER_NAVIGATION_ITEMS.map(({ title, slug }) => {
    if (slug === "offer" && !offerEnabled) return;
    const pathname = `/club/${club}/${slug}`;
    return (
      <Link
        href={{
          pathname,
          query: {},
        }}
        key={slug}
        className="club-footer__navigation-item"
      >
        {t(title)}
      </Link>
    );
  });
});

export { FooterTitleNavigation };
