export const COPYRIGHT_TITLE = "©Instasport";

export const FOOTER_NAVIGATION_ITEMS = [
  {
    slug: "confidential",
    title: "privacy_policy",
  },
  {
    slug: "offer",
    title: "the_contract",
  },
  {
    slug: "rules",
    title: "club_rules",
  },
];
