import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { scrollTo } from '../utils/scrollTo';

// const yOffset = 98;
// const DURATION = 500;
// const POSITION = 'top';

export const HASH_PREFIX = '_';

function ScrollToAnchor() {
  const router = useRouter();

  useEffect(() => {
    const { asPath } = router
    const hash = asPath.match(/#([a-z0-9]+)/gi);

    const id = hash && `${HASH_PREFIX}${hash[0].slice(1)}`
    if (id) {
      const el = document.getElementById(id);

      if (el) scrollTo({ id })
    }

  }, [router]);

  return null;
}

export { ScrollToAnchor };
