import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

export const isServer = () => typeof window === "undefined";

const initialState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: false,
  isReady: false,
};

const initialFullState = {
  accessToken: null,
  refreshToken: null,
  isAuthorized: false,
  isReady: true,
};

const AuthContext = createContext<AuthContextInterface>({
  ...initialState,
  exp: 0,
  signIn: () => null,
  signOut: () => null,
});

export interface AuthStateInterface {
  accessToken: string | null;
  refreshToken: string | null;
  exp?: number;
  isAuthorized: boolean;
  isReady: boolean;
}

export interface AuthContextInterface extends AuthStateInterface {
  signIn: (
    { accessToken, refreshToken }: Tokens,
    updateStorage?: boolean
  ) => void;
  signOut: () => void;
}

interface Tokens {
  accessToken: string | null;
  refreshToken: string | null;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useProviderAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);

function useProviderAuth() {
  // const getTokens = useCallback(() => {
  //   if (isServer()) return initialState;
  //   const tokensData = localStorage.getItem("tokens");
  //   if (tokensData) return JSON.parse(tokensData) ?? initialState;
  //   return initialState;
  // }, []);

  const [{ accessToken, refreshToken, isAuthorized, isReady }, setAuthState] =
    useState<AuthStateInterface>(initialState); // authState

  useEffect(() => {
    const tokensData = localStorage.getItem("tokens");

    setAuthState(
      tokensData
        ? {
            ...(JSON.parse(tokensData) ?? initialFullState),
            isReady: true,
          }
        : initialFullState
    );

    // localStorage.setItem("tokens", JSON.stringify(authState));
  }, []);

  const signIn = useCallback(
    (
      {
        accessToken,
        refreshToken,
      }: { accessToken: string | null; refreshToken: string | null },
      updateStorage = true
    ) => {
      const tokensData = {
        accessToken,
        refreshToken,
        exp: Date.now() + 5 * 60 * 1000, //add 5 mins
        isAuthorized: true,
      };

      if (updateStorage)
        localStorage.setItem("tokens", JSON.stringify(tokensData));
      setAuthState({ ...tokensData, isReady: true });
    },
    []
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("tokens");
    setAuthState(initialFullState);
  }, []);

  return { accessToken, refreshToken, isAuthorized, isReady, signIn, signOut };
}
