import { SET_CLUB_COLORS } from "../types";

const DEFAULT_STATE = {
  primaryColor: '#ffffff', // '#da052c', //'unset',
  primaryTextColor: '#ffffff',//'#ffffff',
  secondaryColor: '#2b2b2b',//'#ffffff',
  secondaryTextColor: '#da052c', //'#2b2b2b',
};

const clubColors = (
  state = DEFAULT_STATE,
  { type, primaryColor, primaryTextColor, secondaryColor, secondaryTextColor }
) => {
  switch (type) {
    case SET_CLUB_COLORS:
      return {
        primaryColor,
        primaryTextColor,
        secondaryTextColor,
        secondaryColor,
      };

    default:
      return state;
  }
};

export default clubColors;
