import { gql } from "@apollo/client";

// export const DASHBOARD = gql`
//   query Dashboard {
//     dashboard {
//       clubs
//       visits
//       events
//       googleTag
//       facebookPixel
//     }
//   }
// `;

export const GET_SITEMAP_URLS = gql`
  query SitemapUrlsQuery {
    sitemapUrls
  }
`;

export const SOCIAL_NETWORKS = gql`
  query Social {
    socialNetworks: settings {
      socialFacebook
      socialTelegram
      socialInstagram
      socialYoutube
    }
  }
`;

export const TOP_CLUBS = gql`
  query Club {
    clubs(page: 1, pageSize: 16) {
      id
      title
      titleShort
      icon
      slug
    }
  }
`;

export const GET_CONTACTS = gql`
  query Partner($slug: String!) {
    social: partner(slug: $slug) {
      id
      phone
      email
      reviews {
        id
      }
    }
  }
`;

export const GET_BANNER = gql`
  query Banner {
    banner {
      id
      image
    }
  }
`;

export const GET_PARTNER_SETTINGS = gql`
  query Partner($slug: String!) {
    social: partner(slug: $slug) {
      id
      slug
      title
      description

      phone
      email
      events
      clubs: allClubs
      visits: allVisits
      binotelId
      googleTag
      facebookPixel
      socialFacebook
      socialTelegram
      socialInstagram
      socialYoutube

      tagTitle
      tagDescription
      tagKeywords
      ogType
      ogSiteName
      ogTitle
      ogDescription
      ogImage
      ogImageWidth
      ogImageHeight
      ogImageAlt
      ogImageType
      seoText

      highlights {
        id
        order
        title
      }
      advantages {
        id
        order
        title
        description
        icon
      }

      priceBlocks {
        id
        order
        title
        header
        footer
        default

        features {
          id
          order
          title
        }
        prices {
          id
          order
          title
          price
          priceFull
          currencyTitle
        }
      }

      features {
        id
        slug
        order
        title
        description
        image
      }
    }

    banner {
      id
      image
      imageMobile
    }
  }
`;

export const PRICES = gql`
  query Prices {
    settings {
      priceBase
      priceClub
      priceHall
      priceTelegram
      priceApplication
      pricePlugin
      googleTag
    }
  }
`;

export const CLUB_BY_SLUG = gql`
  query ClubBySlug($slug: String!) {
    clubBySlug(slug: $slug) {
      id
      title
    }
  }
`;

export const CONFIDENTIAL_AGREEMENT = gql`
  query ConfidentialAgreement {
    confidentialAgreement {
      text
    }
  }
`;

export const PARTNERS = gql`
  query Partners {
    partners {
      id
      slug
      title
      description
      icon
      clubs
    }
  }
`;

export const REVIEWS = gql`
  query Reviews {
    reviews {
      id
      order
      title
      description
      text
      video
      profile {
        user {
          firstName
          lastName
          avatar: avatarUser
        }
        avatar
      }
    }
  }
`;
