import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import { Portal } from "../Portal";

const AlertBlock = ({
  children,
  onClose,
  initialWidth = false,
  innerFull = false,
  className = "",
}) => {
  return (
    <Portal>
      <div
        className={clsx("alert-modal", {
          "alert-modal--full": initialWidth,
          [className]: !!className,
        })}
      >
        <div
          className="alert-block"
          style={initialWidth ? { maxWidth: "1000px" } : {}}
        >
          <div className="alert-block__close-button" onClick={onClose}>
            <div className="alert-block__close-dash1"></div>
            <div className="alert-block__close-dash2"></div>
          </div>
          <div className="alert-block-inner">
            <div
              className="alert-block__scroll-wrapper"
              {...(innerFull && { style: { maxWidth: "100%" } })}
            >
              {children}
            </div>
          </div>
        </div>
        <style jsx global>{`
          @import "./AlertBlock.scss?5";
        `}</style>
      </div>
    </Portal>
  );
};

AlertBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  onClose: PropTypes.func,
};

AlertBlock.defaultProps = {
  children: <></>,
  onClose: () => {},
};

export default AlertBlock; //HocWithPortal
