import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/index";
import { composeWithDevTools } from "redux-devtools-extension";

import { save, load } from "redux-localstorage-simple";

const loadConfig = {
  states: ["pageCity", "authorization"],
  namespace: "page-data",
  disableWarnings: true,
  preloadedState: {
    authorization: {
      showAuthorization: false,

      nextUrl: null,

      setHeader: false,
      isEmailReset: false,

      finishFunc: () => {},
    },
  },
};

const configureStore = createStore(
  rootReducer,
  // preloadedState,
  load(loadConfig),
  composeWithDevTools(
    applyMiddleware(
      // thunk,
      save({
        states: ["authorization"],
        namespace: "page-data",
      })
    )
  )
);

// const store = configureStore(load(loadConfig));

export default configureStore;
