import React from "react";

import { Portal } from "../components/common/Portal";

export const HocWithPortal =
  (Component, isPortal = true) =>
  (props) => {
    if (!isPortal) return <Component />;
    return (
      <Portal>
        <Component {...props} />
      </Portal>
    );
    // return ReactDOM.createPortal(
    //   <Component {...props} />,
    //   document.querySelector("#modal")
    // );
  };
