import { appWithTranslation } from "next-i18next";

// if (true) {
//   import("../../styles/_fontFamily.scss");
// }

import "../../styles/globals.scss";

import "swiper/swiper.min.css";

// import { ProvideMediaContext } from "../context/mediaQuery/MediaQueryContext";
// import "webix/webix.min.css";
// import Script from "next/script";
import nextI18nextConfig from "../../next-i18next.config";

import { Layout } from "../components/common/Layout";
import { useApollo } from "../utils/appollo";
import store from "../utils/redux/store";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";

import { CLUB_NAVBAR } from "../constants/headerTypes";
import { CLUB_FOOBAR } from "../constants/footerTypes";
import { getSeoFields } from "../utils/getSeoFields";
import { AuthProvider } from "../utils/auth";
// import { OWN_DOMAIN } from "../constants/APIKeys";

function App(props) {
  return (
    <AuthProvider>
      {/* <Script
    //       id="gtm-script"
    //       src={`https://www.googletagmanager.com/gtm.js?id=GTM-T3372V6`}
    //     /> */}

      <WithApolloProvider props={props} />
    </AuthProvider>
  );
}

function WithApolloProvider({ props: { Component, pageProps } }) {
  const client = useApollo(pageProps);

  const {
    club,
    tags = getSeoFields(),
    authProtected,
    additionalQueryFields,
  } = pageProps;

  // console.log(pageProps);
  // const Layout = Component.layout || (({ children }) => <>{children}</>);
  const navbar = club
    ? CLUB_NAVBAR
    // : OWN_DOMAIN
    // ? EMPTY_NAVBAR
    : Component.navbar;
  const foobar = club
    ? CLUB_FOOBAR
    // : OWN_DOMAIN
    // ? EMPTY_NAVBAR
    : Component.foobar;

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        {/* <ProvideMediaContext> */}
        <Layout
          navbar={navbar}
          foobar={foobar}
          tags={tags}
          authProtected={authProtected}
          additionalQueryFields={additionalQueryFields}
        >
          <Component {...pageProps} />
        </Layout>
        {/* </ProvideMediaContext> */}
      </Provider>
    </ApolloProvider>
  );
}

export default appWithTranslation(App, nextI18nextConfig);
