import { combineReducers } from "redux";

import navbarLinks from "./navbarLinks";

import authorization from "./authorization";

import clubColors from "./clubColors";

import clubNotification from "./clubNotification";

const rootReducer = combineReducers({
  navbarLinks,
  authorization, //
  clubColors,
  clubNotification,
});

export default rootReducer;
