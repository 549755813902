export const IPSTACK_ACCESS_KEY = process.env.IPSTACK_ACCESS_KEY;
export const MAP_KEY = process.env.MAP_KEY;
export const API_DEFAULT_KEY = process.env.API_DEFAULT_KEY;
// export const BASE_URL = process.env.BASE_URL; //'https://7ac1-46-219-228-140.ngrok.io';

export const API_URL = `${process.env.API_URL}/`;
export const SITE_URL = process.env.SITE_URL;
export const IS_FULL_CLIENT_SITE = process.env.CLIENT_SITE == "true";
export const DEVELOP_TEST = process.env.DEVELOP_TEST == "true";
export const OWN_DOMAIN = process.env.OWN_DOMAIN;

export const IPSTACK_URL = "https://api.ipstack.com/check?access_key="; //"https://ipapi.co/json";
