import React, { memo } from "react";
import { CLUB_FOOBAR, DEFAULT_FOOBAR, } from "../../../constants/footerTypes";
import { DefaultFooter } from "./DefaultFooter";
import { ClubFooter } from "./ClubFooter";
import { EMPTY_NAVBAR } from '../../../constants/headerTypes';

const Footer = memo(({ foobar = DEFAULT_FOOBAR }) => {

  const foobarComponent = {
    [CLUB_FOOBAR]: (
      <ClubFooter />
    ),
    [EMPTY_NAVBAR]: null,
    [DEFAULT_FOOBAR]: <DefaultFooter />,
    null: <DefaultFooter />,
  };

  return foobarComponent[foobar]
});

export { Footer };
