// const { getDefaultLocale } = require("./src/utils/common/getDefaultLocale");

// let i18n = null;

// module.exports = {
//   // i18n,
//   getI18n: (async function () {
//     i18n = await getDefaultLocale();
//     return i18n;
//   })(),
// };

module.exports = {
  i18n: {
    defaultLocale: process.env.DEFAULT_LOCALE, // "ru",
    locales: process.env.LOCALES.split(" "), //["en", "ru", "uk","pl","de","kz","ka"], //ka - georgian
    localeDetection: false,

    // localePath: path.resolve("./public/locales"),
    // domains: [
    //   {
    //     domain: "localhost:3000",
    //     defaultLocale: "ru",
    //     http: true,
    //   },
    //   {
    //     domain: "localhost:3000/uk",
    //     defaultLocale: "uk",
    //     http: true,
    //   },
    //   {
    //     domain: "localhost:3000/en",
    //     defaultLocale: "en",
    //     http: true,
    //   },
    // ],
  },
};
